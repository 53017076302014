import * as React from 'react';
import styled from 'styled-components';
import { HeaderDescription } from '../molecules/HeaderDescription';
import wavesThird from "../../images/waves-third.jpg";
import ToolsHeader from "../../images/tools-header.svg";
import { SignatureHeader } from '../molecules/SignatureHeader';
import { ReactNode } from 'react';
import { WpUser } from '../../shared/wp-user-utils';


type HeaderProps = {
    title: string | ReactNode; 
    subTitle?: string; 
    description: string | ReactNode;
    textInDownPosition?: boolean;
    isBackgroundImage?: boolean;
    backgroundImage?: string;
    isDiffrentBackgroundImageForMobile?: boolean;
    buttonText?: string;
    buttonLink?: string;
    headerImage?: string | ReactNode;
    headerImageWidth?: number;
    user?: WpUser;
    signatureAuthor?: string;
    signaturePosition?: string;
    signatureShareLink?: string;
    signaturePhoneNumber?: string;
    signatureDeskPhoneNumber?: string;
    signatureEmail?: string;
    signatureCity?: string;
    isSignature?: boolean;
    isArrowNavVisible?: boolean;
    firstHeaderNavLink?: string;
    secondHeaderNavLink?: string;
    firstHeaderNavLinkName?: string;
	secondHeaderNavLinkName?: string;
    isToolsHeader?: boolean;
    name?: string;
    SEOTitle?: string;
    SEODescription?: string;
}


type StyledImageProps = {
    width: number;
}


const Background = styled.div<Pick<HeaderProps, "backgroundImage" | "isBackgroundImage" | "textInDownPosition" | "name" | "isDiffrentBackgroundImageForMobile">>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    min-height: -webkit-fill-available;
    background-image: ${({backgroundImage, isBackgroundImage}) => 
        isBackgroundImage && `url(${backgroundImage || wavesThird})`};
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    align-items: ${({textInDownPosition}) => textInDownPosition ? "flex-end" :  "center"};
    padding-bottom: ${({textInDownPosition}) => textInDownPosition ? "50px" :  0};



    @media (min-width: 1200px) and (max-width: 1600px) {
        background-size: cover;
        align-items: ${({textInDownPosition}) => textInDownPosition ? "flex-end" :  "center"};
        padding-bottom: ${({textInDownPosition}) => textInDownPosition ? "50px" :  0};
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        height: 800px;
    }


    @media (min-width: 768px) and (max-width: 991.98px) {
        padding-top: 75px;
        flex-direction: column;
        align-items: flex-start;
        background-size: cover;
        justify-content: ${({textInDownPosition}) => textInDownPosition ? "flex-end" :  "flex-start"};
        
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        background-image: ${({backgroundImage, isBackgroundImage, isDiffrentBackgroundImageForMobile}) => 
        isBackgroundImage && `url(${isDiffrentBackgroundImageForMobile ? wavesThird : backgroundImage})`};
        display: block;
        height: auto;
        background-position: right top;
        background-size: 300%;
        padding-top: 75px;
        padding-bottom: 80px;
    }
`;

const HeaderBox = styled.div<Pick<HeaderProps, "textInDownPosition">>`

        @media (min-width: 768px) and (max-width: 991.98px) {
        display: flex;
        flex-direction: column;
        justify-content: ${({textInDownPosition}) => textInDownPosition ? "flex-end" :  "flex-start"};
        padding: 0;
    }
`;

const ImageBox = styled.div`
    flex-basis: 50%;
    padding: ${({ theme }) => theme.paddingDesktop} 0 0; 
    text-align: right;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop} 0 0 ; 
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop} 0 0 ; 
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-basis: 35%;
        width: 100%;
        padding: 0;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: auto;
     }

`;
 
const StyledImage = styled.img`

    @media (min-width: 1200px) and (max-width: 1600px) {
        width: ${(props: StyledImageProps) => `${props.width * 0.7}px`};
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        width: ${(props: StyledImageProps) => `${props.width * 0.63}px`};
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        display: block;
        margin: 0 auto;
        width: ${(props: StyledImageProps) => `${props.width * 0.8}px`};
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 326px;
    }
`;

const StyledImageTools = styled.img`
    display: none;

    @media (min-width: 1px) and (max-width: 1000px) {
        display: block;
        width: 326px;
    }
`;

const StyledToolsChildren = styled.div`
    justify-content: flex-end;
    display: flex;
`;


export const Header: React.FC<HeaderProps> = ({
    title, 
    subTitle, 
    description, 
    textInDownPosition = false,
    backgroundImage = wavesThird,
    isDiffrentBackgroundImageForMobile = false,
    buttonText, 
    buttonLink, 
    headerImage, 
    headerImageWidth = 600, 
    isSignature = false,
    user, 
    signatureAuthor, 
    signaturePosition, 
    signatureShareLink, 
    signaturePhoneNumber,
    signatureDeskPhoneNumber,
    signatureEmail,
    signatureCity,
    isArrowNavVisible = false, 
    firstHeaderNavLink, 
    secondHeaderNavLink,
    firstHeaderNavLinkName,
	secondHeaderNavLinkName,
    isBackgroundImage = true,
    isToolsHeader = false,
    SEOTitle,
    SEODescription
 }) => {

 
    return (
        <Background 
            name="header" 
            backgroundImage={backgroundImage} 
            isBackgroundImage={isBackgroundImage} 
            textInDownPosition={textInDownPosition} 
            isDiffrentBackgroundImageForMobile={isDiffrentBackgroundImageForMobile}>
            
            <HeaderBox textInDownPosition={textInDownPosition}>
                <HeaderDescription
                    title={title}
                    subTitle={subTitle}
                    description={description}
                    buttonText={buttonText}
                    buttonLink={buttonLink}
                    user={user}
                    signatureAuthor={signatureAuthor}
                    signaturePosition={signaturePosition} 
                    signatureShareLink={signatureShareLink} 
                    isArrowNavVisible={isArrowNavVisible}
                    firstHeaderNavLink={firstHeaderNavLink}
                    secondHeaderNavLink={secondHeaderNavLink}
                    firstHeaderNavLinkName={firstHeaderNavLinkName}
	                secondHeaderNavLinkName={secondHeaderNavLinkName}
                    signaturePhoneNumber={signaturePhoneNumber}
                    signatureDeskPhoneNumber={signatureDeskPhoneNumber}
                    signatureEmail={signatureEmail}
                    signatureCity={signatureCity}
                    isSignature={isSignature}
                />
            </HeaderBox>

            <ImageBox>
                {typeof(headerImage) === "string" ? <StyledImage
                        src={headerImage}
                        alt="headerImage"
                        width={headerImageWidth}
                        loading="eager"
                    /> 
                    :
                    <StyledToolsChildren>
                        {headerImage}
                    </StyledToolsChildren>
                }
                {isToolsHeader && <StyledImageTools
                    src={ToolsHeader}
                    alt="Tools Header"
                    loading="eager"
                />}
            </ImageBox>

             { isSignature &&
                <SignatureHeader 
                    user={user}
                    author={signatureAuthor} 
                    link={signatureShareLink}
                    phoneNumber={signaturePhoneNumber}
                    email={signatureEmail}
                    SEOTitle={SEOTitle}
                    SEODescription={SEODescription}
                />
             }


        </Background>
    );
}

        
        
        
        
   