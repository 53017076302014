import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { WpUser } from '../../shared/wp-user-utils';
import ButtonAnchor from '../atoms/ButtonAnchor';
import { HeaderNavigation } from '../atoms/HeaderNavigation';
import { SignatureMobile } from './SignatureMobile';


type HeaderDescriptionProps = {
    title: string | ReactNode;
    subTitle: string;
    description: string | ReactNode;
    buttonText: string;
    buttonLink: string;
    user: WpUser;
    signatureAuthor?: string;
    signaturePosition?: string;
    signatureShareLink?: string;
    isArrowNavVisible: boolean;
    firstHeaderNavLink: string;
    secondHeaderNavLink: string;
    firstHeaderNavLinkName?: string;
	secondHeaderNavLinkName?: string;
    signaturePhoneNumber?: string;
    signatureDeskPhoneNumber?: string;
    signatureEmail?: string;
    signatureCity?: string;
    isSignature?: boolean;
}


const HeaderDescriptionContent = styled.header`
    padding: 0 0 ${({ theme }) => theme.paddingDesktop}; 
    width: 100%;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 0 0 ${({ theme }) => theme.paddingLaptop} ; 
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: 0 0 ${({ theme }) => theme.paddingLaptop} ; 
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
     }
`;

export const Title = styled.h1`
    margin: 17px 0 48px 0;
    color: ${({ theme }) => theme.primaryColor};

     span {
        font-family: 'Museo';
        font-weight: 600;
        font-size: 46px;
        line-height: 86px;
     }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
          span {
            display: block;
          }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin: 17px 0 35px 0;
        span {
            font-size: 46px;
        }
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;

        span {
            display: block;
            font-size: 46px;
            line-height: 1;
            padding: 0;
        }
     }
    
`

const StyledButtonAnchor = styled(ButtonAnchor)`
    width: 180px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
        margin-right: 16px; 
     }
`;

const SubTitle = styled.h2`
    color: ${({ theme }) => theme.primaryColor};
    line-height: 1.1;
    font-size: ${({ theme }) => theme.fontSize.h3}; 
    @media (min-width: 1px) and (max-width: 767.98px) {
        font-size: 26px;
        color: ${({ theme }) => theme.blackColor};
     }
`;

export const Description = styled.p`
    font-size: 24px;
    margin-bottom: 40px;

    @media (min-width: 992px) and (max-width: 1600px) {
        font-size: 20px;
        margin-bottom: 30px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 22px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        font-size: 23px;
    }
`;


export const HeaderDescription: React.FC<HeaderDescriptionProps> = ({
    title, 
    subTitle, 
    description, 
    buttonText, 
    buttonLink, 
    user, 
    signatureAuthor, 
    signaturePosition, 
    signatureShareLink, 
    isArrowNavVisible, 
    firstHeaderNavLink, 
    secondHeaderNavLink,
    firstHeaderNavLinkName,
	secondHeaderNavLinkName,
    signaturePhoneNumber,
    signatureDeskPhoneNumber,
    signatureEmail,
    signatureCity,
    isSignature
 }) => {

    return ( 
        <HeaderDescriptionContent> 

            <HeaderNavigation 
                isArrowVisible={isArrowNavVisible} 
                firstLink={firstHeaderNavLink} 
                secondLink={secondHeaderNavLink}  
                firstLinkName={firstHeaderNavLinkName}
	            secondLinkName={secondHeaderNavLinkName}
            />   

            <Title>{title}</Title>
            { subTitle && <SubTitle>{subTitle}</SubTitle> }
            <Description>{description}</Description> 
            
             { buttonText && buttonLink && <StyledButtonAnchor href={buttonLink} type="blue">{buttonText}</StyledButtonAnchor> }
            

            {isSignature &&
                    <SignatureMobile 
                        user={user}
                        author={signatureAuthor} 
                        link={signatureShareLink}
                        phoneNumber={signaturePhoneNumber}
                        email={signatureEmail}
                    />
                }
        </HeaderDescriptionContent>
     );
}
